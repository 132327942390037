// components/Modal.js

import React from 'react';
import './Modal.css'; // We'll create this CSS file next

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  // Close modal when clicking outside the content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;
