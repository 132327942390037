import React, { useState } from 'react';
import { Moon, Sun, Building2 } from 'lucide-react';
import logo from '../images/logoFull.png';
import CompanyList from './CompanyList';
import Modal from './Modal';

function Header({ darkMode, toggleDarkMode }) {
  const [isCompanyListOpen, setIsCompanyListOpen] = useState(false);

  return (
    <header className="app-header">
      <div className="flex items-center gap-4">
        <div className="logo-container">
          <img src={logo} alt="FinSight Logo" className="app-logo" />
        </div>
        
        
      </div>
      <div>
      <button 
          className="company-list-button"
          onClick={() => setIsCompanyListOpen(true)}
          title="Şirket Listesi"
        >
          <span className="icon-wrapper">
            <Building2 size={16} strokeWidth={2.5} />
          </span>
          <span className="button-text">Şirket Listesi</span>
      </button>
      </div>
      <button 
        className="dark-mode-toggle flex items-center justify-center" 
        onClick={toggleDarkMode}
        title={darkMode ? "Açık Tema" : "Koyu Tema"}
      >
        {darkMode ? <Sun size={24} /> : <Moon size={24} />}
      </button>
      
      <Modal isOpen={isCompanyListOpen} onClose={() => setIsCompanyListOpen(false)}>
        <CompanyList 
          isOpen={isCompanyListOpen} 
          onClose={() => setIsCompanyListOpen(false)} 
        />
      </Modal>
    </header>
  );
}

export default Header;