import React from 'react';

const suggestedPrompts = [
  "Hangi firmalarla ilgili bilgi alabilirim?",
  "Sobancı Holding'in 2023 Net Karı Kaçtır?",
  "Brisa 1. ve 2. çeyrek nek karları nedir?",
  "Eczacıbaşı Yönetim Kurulunda Kimler Var?"
];

function Welcome({ onSendMessage }) {
  return (
    <div className="welcome-container">
      <h2>Yatırımcı İlişkileri Danışmanınıza Hoşgeldiniz</h2>
      <p>Aşağıdaki Örneklerle Sohbete Başlayabilirsiniz:</p>
      <div className="suggested-prompts">
        {suggestedPrompts.map((prompt, index) => (
          <button key={index} onClick={() => onSendMessage(prompt)}>
            {prompt}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Welcome;