import React, { useState } from 'react';
import { Send, Trash2 } from 'lucide-react';

function ChatForm({ onSendMessage, onClearChat }) {
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() === '') return;
    onSendMessage(message);
    setMessage('');
  };

  return (
    <form className="chat-form" onSubmit={handleSubmit}>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here..."
      />
      <button type="submit" disabled={message.trim() === ''}>
        <Send size={24} />
      </button>
      <button type="button" onClick={onClearChat} className="clear-chat-btn">
        <Trash2 size={24} />
      </button>
    </form>
  );
}

export default ChatForm;