import React, { useState, useEffect, useMemo } from 'react';
import { Building2, Search, FileText, Calendar, X, RefreshCcw } from 'lucide-react';

// Parse filename into structured data
const parseFileName = (filename) => {
  const parts = filename.split('_');
  const company = parts[0];
  
  // Find the index of "Donem"
  const donemIndex = parts.findIndex(part => part === "Donem");
  let term = "Diğer";
  
  if (donemIndex !== -1 && parts[donemIndex + 1]) {
    if (parts[donemIndex + 1] === "Yillik") {
      term = "Yıllık";
    } else if (parts[donemIndex + 1].match(/^\d+$/)) {
      term = `${parts[donemIndex + 1]}. Çeyrek`;
    }
  }

  // Extract years - look for 4-digit numbers
  const years = parts.filter(part => /^20\d{2}$/.test(part));

  return {
    company,
    years,
    term,
    fullName: filename
  };
};

// Client-side cache
let clientCache = null;

const CompanyList = ({ isOpen, onClose }) => {
  const [files, setFiles] = useState(clientCache || []);
  const [loading, setLoading] = useState(!clientCache);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedYear, setSelectedYear] = useState('all');
  const [selectedTerm, setSelectedTerm] = useState('all');
  const [selectedCompany, setSelectedCompany] = useState('all');

  // Add this new function inside the component
  const handleRefresh = async () => {
    clientCache = null; // Clear client cache
    setLoading(true);
    setError(null);
    setFiles([]);
    
    try {
      // Force refresh from vector store
      const response = await fetch('/api/companies?refresh=true');
      if (!response.ok) throw new Error('Dosya listesi yenilenemedi');
      const data = await response.json();
      setFiles(data);
      clientCache = data;
      setLoading(false);
    } catch (err) {
      setError('Yenileme sırasında bir hata oluştu: ' + err.message);
      setLoading(false);
    }
  };
  const processedData = useMemo(() => {
    const data = files.map(parseFileName);
    return data.sort((a, b) => a.company.localeCompare(b.company));
  }, [files]);

  // Extract unique values for filters
  const { companies, years, terms } = useMemo(() => {
    const uniqueCompanies = new Set();
    const uniqueYears = new Set();
    const uniqueTerms = new Set();

    processedData.forEach(item => {
      uniqueCompanies.add(item.company);
      item.years.forEach(year => uniqueYears.add(year));
      uniqueTerms.add(item.term);
    });

    return {
      companies: Array.from(uniqueCompanies).sort(),
      years: Array.from(uniqueYears).sort().reverse(),
      terms: Array.from(uniqueTerms).sort()
    };
  }, [processedData]);

  // Filter data
  const filteredData = useMemo(() => {
    return processedData.filter(item => {
      const matchesSearch = item.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
                           item.fullName.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesYear = selectedYear === 'all' || item.years.includes(selectedYear);
      const matchesTerm = selectedTerm === 'all' || item.term === selectedTerm;
      const matchesCompany = selectedCompany === 'all' || item.company === selectedCompany;
      
      return matchesSearch && matchesYear && matchesTerm && matchesCompany;
    });
  }, [processedData, searchTerm, selectedYear, selectedTerm, selectedCompany]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch('/api/companies');
        if (!response.ok) throw new Error('Dosya listesi alınamadı');
        const data = await response.json();
        setFiles(data);
        clientCache = data;
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (isOpen && !clientCache) {
      setLoading(true);
      setError(null);
      fetchFiles();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="company-list-container">
      {/* Sidebar */}
      <div className="sidebar">
          <div className="title-container flex justify-between items-center">
            <div>
              <h2 className="title-main">
                <span className="icon">
                  <Building2 size={24} />
                </span>
                Şirket Listesi
              </h2>
              <p className="title-subtitle">
                Tüm şirket raporlarını görüntüleyin ve filtreleyebilirsiniz
              </p>
            </div>
            <button
              onClick={handleRefresh}
              disabled={loading}
              className={`refresh-button flex items-center gap-2 px-4 py-2 rounded-lg transition-all 
                ${loading ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
            >
              <RefreshCcw size={20} className={`text-white ${loading ? 'animate-spin' : ''}`} />
              <span className="text-white">Yenile</span>
            </button>
          </div>

        {/* Filters */}
        <div className="filter-section">
          <h3>Filtreler</h3>
          <div className="space-y-4">
            <div>
              <label className="text-sm text-gray-400 block mb-2">Şirket</label>
              <select
                className="filter-input"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              >
                <option value="all">Tüm Şirketler</option>
                {companies.map(company => (
                  <option key={company} value={company}>{company}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-sm text-gray-400 block mb-2">Yıl</label>
              <select
                className="filter-input"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="all">Tüm Yıllar</option>
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="text-sm text-gray-400 block mb-2">Dönem</label>
              <select
                className="filter-input"
                value={selectedTerm}
                onChange={(e) => setSelectedTerm(e.target.value)}
              >
                <option value="all">Tüm Dönemler</option>
                {terms.map(term => (
                  <option key={term} value={term}>{term}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Search */}
        <div className="filter-section">
          <h3>Arama</h3>
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Dosya ara..."
              className="filter-input pl-10"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Active Filters */}
        {(selectedYear !== 'all' || selectedTerm !== 'all' || searchTerm || selectedCompany !== 'all') && (
          <div className="filter-section">
            <h3>Aktif Filtreler</h3>
            <div className="flex flex-wrap gap-2">
              {selectedCompany !== 'all' && (
                <span className="filter-tag">
                  {selectedCompany}
                  <X 
                    className="remove-icon h-4 w-4" 
                    onClick={() => setSelectedCompany('all')} 
                  />
                </span>
              )}
              {selectedYear !== 'all' && (
                <span className="filter-tag">
                  {selectedYear}
                  <X 
                    className="remove-icon h-4 w-4" 
                    onClick={() => setSelectedYear('all')} 
                  />
                </span>
              )}
              {selectedTerm !== 'all' && (
                <span className="filter-tag">
                  {selectedTerm}
                  <X 
                    className="remove-icon h-4 w-4" 
                    onClick={() => setSelectedTerm('all')} 
                  />
                </span>
              )}
              {searchTerm && (
                <span className="filter-tag">
                  "{searchTerm}"
                  <X 
                    className="remove-icon h-4 w-4" 
                    onClick={() => setSearchTerm('')} 
                  />
                </span>
              )}
            </div>
          </div>
        )}

        {/* Stats */}
        <div className="space-y-4 mb-6">
          <div className="stats-card">
            <div className="text-sm text-gray-400">Toplam Şirket</div>
            <div className="stats-number">{companies.length}</div>
          </div>
          <div className="stats-card">
            <div className="text-sm text-gray-400">Toplam Rapor</div>
            <div className="stats-number">{files.length}</div>
          </div>
          <div className="stats-card">
            <div className="text-sm text-gray-400">Yıl Aralığı</div>
            <div className="stats-number">{years[years.length - 1]} - {years[0]}</div>
          </div>
          <div className="stats-card">
            <div className="text-sm text-gray-400">Filtrelenmiş</div>
            <div className="stats-number">{filteredData.length}</div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {loading ? (
          <div className="flex flex-col items-center justify-center h-[80vh]">
            <div className="loading-icon"></div>
            <h2 className="loading-title mt-6">Şirket Listesi Yükleniyor</h2>
            <p className="loading-subtitle mt-2">
              Vektör veritabanından şirket raporları getiriliyor...
            </p>
            <div className="loading-progress mt-6"></div>
          </div>
        ) : error ? (
          <div className="text-center text-red-500 p-8">
            <FileText className="h-16 w-16 mx-auto mb-4 opacity-50" />
            <p>{error}</p>
          </div>
        ) : filteredData.length === 0 ? (
          <div className="text-center text-gray-500 p-8">
            <FileText className="h-16 w-16 mx-auto mb-4 opacity-50" />
            <p>Sonuç bulunamadı</p>
          </div>
        ) : (
          <div className="documents-grid">
            {filteredData.map((item, index) => (
              <div key={index} className="document-card p-4">
                <div className="flex items-start gap-3">
                  <FileText className={`h-6 w-6 mt-1 ${
                    item.term === 'Yıllık' ? 'text-blue-500' : 'text-green-500'
                  }`} />
                  <div className="flex-1 min-w-0">
                    <div className="text-lg font-medium truncate">{item.company}</div>
                    <div className="text-sm text-blue-400 dark:text-blue-300 mb-1">
                      {item.term}
                    </div>
                    <div className="flex items-center text-sm text-gray-400">
                      <Calendar className="h-4 w-4 mr-1" />
                      {item.years.join(', ')}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyList;