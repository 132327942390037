// Message.js

import React from 'react';
import { User, Bot } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // Import remark-gfm
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Message({ sender, text, isTyping, imageData, openModal }) {
  const CodeBlock = ({ language, value }) => {
    return (
      <SyntaxHighlighter language={language} style={tomorrow}>
        {value}
      </SyntaxHighlighter>
    );
  };

  return (
    <div className={`message ${sender}`}>
      <div className="message-avatar">
        {sender === 'user' ? <User size={24} /> : <Bot size={24} />}
      </div>
      <div className="message-content">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]} // Add remarkGfm plugin here
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <CodeBlock
                  language={match[1]}
                  value={String(children).replace(/\n$/, '')}
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            // Optionally, you can customize how tables are rendered
            table({ children, ...props }) {
              return (
                <table className="markdown-table" {...props}>
                  {children}
                </table>
              );
            },
          }}
        >
          {text}
        </ReactMarkdown>
        {imageData && (
          <img
            src={`data:image/png;base64,${imageData}`}
            alt="Generated"
            className="chat-image"
            onClick={() => openModal(`data:image/png;base64,${imageData}`)} // Trigger modal
          />
        )}
        {isTyping && (
          <div className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Message;
