// App.js

import React, { useState, useEffect } from 'react';
import ChatWindow from './components/ChatWindow';
import ChatForm from './components/ChatForm';
import Header from './components/Header';
import Welcome from './components/Welcome';
import Modal from './components/Modal'; // Import Modal
import './styles.css';

function App() {
  const [messages, setMessages] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [threadId, setThreadId] = useState(null);

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  useEffect(() => {
    // Generate or retrieve user ID
    if (!localStorage.getItem('userId')) {
      const userId = 'user-' + Math.random().toString(36).substr(2, 9);
      localStorage.setItem('userId', userId);
    }

    // Always create a new thread on page load
    createNewThread();
  }, []);

  const createNewThread = async () => {
    try {
      //const response = await fetch('http://localhost:3000/api/create-thread', {
      const response = await fetch('/api/create-thread', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: localStorage.getItem('userId') })
      });

      if (!response.ok) {
        throw new Error('Failed to create new thread');
      }

      const { threadId } = await response.json();
      setThreadId(threadId);
      // We don't store threadId in localStorage anymore
      setMessages([]); // Clear messages for the new thread
    } catch (error) {
      console.error('Error creating new thread:', error);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  const escapeMarkdown = (text) => {
    return text.replace(/`/g, '\\`');
  };

  const handleClearChat = () => {
    createNewThread();
  };

  const sendMessage = async (message) => {
    if (!threadId) {
      console.error('No thread ID available');
      return;
    }

    const newUserMessage = { sender: 'user', text: message };
    const newAssistantMessage = { sender: 'assistant', text: '', isTyping: true };
    setMessages(prevMessages => [...prevMessages, newUserMessage, newAssistantMessage]);

    try {
      //const response = await fetch('http://localhost:3000/api/chat', {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message,
          userId: localStorage.getItem('userId'),
          threadId: threadId
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setMessages(prevMessages =>
            prevMessages.map((msg, index) =>
              index === prevMessages.length - 1 ? { ...msg, isTyping: false } : msg
            )
          );
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        let boundary = buffer.indexOf('\n\n');

        while (boundary !== -1) {
          const event = buffer.slice(0, boundary);
          buffer = buffer.slice(boundary + 2); // Remove the processed event from buffer

          if (event.startsWith('data: ')) {
            const data = event.slice(6).trim();
            //console.log('Received event:', data);

            if (data === '[DONE]') {
              continue;
            }

            try {
              const parsed = JSON.parse(data);
              if (parsed.text) {
                //console.log(parsed.text);
                const escapedText = escapeMarkdown(parsed.text);
                setMessages(prevMessages => {
                  const lastMessage = prevMessages[prevMessages.length - 1];
                  const updatedLastMessage = {
                    ...lastMessage,
                    text: (lastMessage.text || '') + escapedText,
                    isTyping: true,
                  };
                  return [...prevMessages.slice(0, -1), updatedLastMessage];
                });
              } else if (parsed.type === 'image') {
                // Handle image data
                //console.log(parsed.data);
                setMessages(prevMessages => {
                  const lastMessage = prevMessages[prevMessages.length - 1];
                  if (lastMessage.sender === 'assistant') {
                    const updatedLastMessage = {
                      ...lastMessage,
                      imageData: parsed.data,
                      isTyping: false,
                    };
                    return [...prevMessages.slice(0, -1), updatedLastMessage];
                  } else {
                    const imageMessage = {
                      sender: 'assistant',
                      imageData: parsed.data,
                      isTyping: false,
                    };
                    return [...prevMessages, imageMessage];
                  }
                });
              }
            } catch (error) {
              console.error('Failed to parse event data:', data, error);
            }
          }

          boundary = buffer.indexOf('\n\n');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [
        ...prevMessages.slice(0, -1),
        { sender: 'assistant', text: 'Sorry, there was an error processing your request.', isTyping: false }
      ]);
    }
  };

  // Modal handlers
  const openModal = (imageSrc) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage('');
  };

  return (
    <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <main className="main-content">
        {messages.length === 0 ? (
          <Welcome onSendMessage={sendMessage} />
        ) : (
          <ChatWindow messages={messages} openModal={openModal} />
        )}
        <ChatForm onSendMessage={sendMessage} onClearChat={handleClearChat} />
      </main>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <img src={modalImage} alt="Enlarged" className="modal-image" />
      </Modal>
    </div>
  );
}

export default App;
